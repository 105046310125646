import { BaseElement, html, css } from 'Components';
import QRCodeStyling from 'qr-code-styling';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        h3 .img_icons {
          height:22px;
        }

        .qrcode {
          float:right;
          margin-left:10px;
          margin-right:5px;
          margin-top:5px;
        }

        .text {
          margin-left:10px;
          margin-right:10px;
          text-align:justify;
        }

        m-icon {
          float:left;
          margin-right:5px;
          font-size:1.2em;
          line-height:28px
        }

        .lineh {
          line-height:30px;
        }

        .normal {
          color:var(--sl-color-orange-500);
        }

        .minor {
          color:var(--sl-color-blue-500);
        }

        .major {
          color:var(--sl-color-red-500);
        }

        .critical {
          color:var(--sl-color-purple-500);
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  async getQrCode(opts = { size: 160 }) {

    async function readBlobAsDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => { resolve(reader.result); };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    const permalink = document.location.href;
    
    const qrCode = new QRCodeStyling({
      width:opts.size,
      height:opts.size,
      margin:0,
      type: 'svg',
      data: permalink,
      //image: '/assets/img/syrcle.svg',
      dotsOptions: {
        color: '#1F1F1F',
        type:'rounded'
        //type: 'dots'
      },
      backgroundOptions: {
        color: '#FFFFFF',
      },
      imageOptions: {
        margin: 0,
        //imageSize:0.4
      }
    });

    const blob = await qrCode.getRawData();
    const dataUri = await readBlobAsDataURL(blob);
    return dataUri;
  }

  async firstUpdated() {
    const img = this.shadowRoot.querySelector('.qrcode img');
    if (!img) return;
    img.src = await this.getQrCode();
  }

  formatStatus(txt) {
    if (!txt) return;
    if (txt.match(/En cours/i)) {
      return html`<m-icon name="pending_actions"></m-icon>${txt}`;
    } else if (txt.match(/Terminé/i)) {
      return html`<m-icon name="checkmark_40">${txt}</m-icon>`;
    }
    return txt;
  }

  formatSeverity(txt) {
    if (!txt) return;
    if (txt.match(/Critique/i)) {
      return html`<m-icon name="dialogs" nogradient class="critical"></m-icon>${txt}`;
    } else if (txt.match(/Majeur/i)) {
      return html`<m-icon name="dialogs" nogradient class="major"></m-icon>${txt}`;
    } else if (txt.match(/Mineur/i)) {
      return html`<m-icon name="dialogs" nogradient class="minor"></m-icon>${txt}`;
    } else if (txt.match(/Modéré/i)) {
      return html`<m-icon name="dialogs" nogradient class="normal"></m-icon>${txt}`;
    }
    return txt;
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin flex2">
        <div style="width:100%">
          <div class="qrcode"><img/></div>
          <div class="lineh">
            <div>Status :</div>
            <div class="bold">${this.formatStatus(this.parent.ticket.statusText)}</div>
          </div>
          <div class="lineh">
            <div>Dernière mise à jour :</div>
            <div class="bold"><m-icon name="event"></m-icon>${this.parent.formatDate(this.parent.ticket.lastUpdated)}</div>
          </div>
          <div class="lineh">
            <div>Séverité : </div>
            <div class="bold">${this.formatSeverity(this.parent.ticket.severityText[0])}</div>
          </div>
          <div class="text" style="margin-top:10px">
            Ce rapport est destiné uniquement aux personnes concernées.
            Celui-ci a pour objectif de suivre le traitement de votre demande d'assistance effectuée auprès de notre service.
            Il vous permet de connaitre l'état d'avancement des opérations.
            Nous vous recommandons d'en effectuer une copie papier afin de le remettre aux autorités compétentes.
          </div>
        </div>
      </div>
    `;
  }

}

customElements.define('incident-block-resume', Block);