import { BaseElement, html, css } from 'Components';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:18px;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/analyste.png" class="img_icons"/>
          ${this.parent.isIncident() ? "Analyste en charge de l'incident" : "Analyste en charge de l'alerte"}
        </section-header>
        <br/>
        <div class="line">
          <div>Analyste :</div>
          <div class="bold">${this.parent.getAnalysteInfo('realName')}</div>
        </div>
        <div class="line">
          <div>Adresse email :</div>
          <div class="bold">${this.parent.getAnalysteInfo('email')}</div>
        </div>
        <div class="line">
          <div>Numéro de téléphone :</div>
          <div class="bold">${this.parent.getAnalysteInfo('phone')}</div>
        </div>
      </div>
    `;
  }

}

customElements.define('incident-block-analyste', Block);