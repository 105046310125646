import { BaseElement, unsafeHTML, html, css, formCss } from 'Components';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      formCss,
      styles,
      css`
        .img_icons {
          height:18px;
        }

        .text {
          margin-left: 10px;
        }

        m-icon[name="edit"] {
          font-size:20px;
          cursor:pointer;
          margin-right:10px;
        }
      `
    ];
  }

  static get translations() {
    return [
      {
        english:{
          translation: {
            infos_declaration:'Declaration informations',
            infos_alert:'Alert informations',
          },
        },
        french:{
          translation: {
            infos_declaration:'Informations concernant la déclaration',
            infos_alert:'Informations concernant l\'alerte',
          }
        }
      }
    ]
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  showEdit() {
    const modal = this.qs('modal-drawer');
    modal.show();

    modal.querySelector('sl-textarea').value = this.parent.ticket.incidentInfosText.join('<br/>');

  }

  render() {
    if (!this.parent.ticket) return;
    
    const title = this.parent.isIncident() ? 'infos_declaration' : 'infos_alert';

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/declaration.png" class="img_icons"/>
          <a id="infos_declaration">${this._tl(title)}</a>
          ${Session.isSoc() 
            ? html`<div slot="right"><m-icon name="edit" pointer nogradient title="Editer" @click="${this.showEdit}"></m-icon></div>`
            : ''
          }
        </section-header>
        <br/>
        <div class="margin text">${unsafeHTML(this.parent.ticket.subjectText)}</div>
        <div class="margin text">${unsafeHTML(this.parent.ticket.incidentInfosText.join('<br/>'))}</div>
      </div>

      <modal-drawer label="Editer">
        <tab-group-lg level="ia" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
          <br/>
          <section-header size="medium">${this._tl(title)}</section-header>
          <sl-textarea size="small" name="content">${this.parent.ticket.incidentInfosText.join('\n')}</sl-textarea><br/>
        </tab-group-lg>
      </modal-drawer>

    `;
  }

}

customElements.define('incident-block-infos-declaration', Block);