import { BaseElement, html, css, unsafeHTML } from 'Components';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:18px;
        }

        hr {
          border: 0;
          border-top: 1px solid var(--sl-color-neutral-300);
          width:97%;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  renderActifs() {
    if (!this.parent.ticket) return;

    if (!this.parent.ticket.agents || this.parent.ticket.agents.length === 0) {
      return html`
        <div class="margin text">
          <div class="line"><div>Aucun actifs trouvés</div></div>
        </div>
        <br/>
      `;
    }

    return html`
      <div class="margin text">
        <div class="line"><div>Identifiant</div><div>Nom réseau de l'actif</div></div>
        <hr/>
        ${this.parent.ticket.agents.map(agent => html`
          <div class="line"><div>${agent.id}</div><div class="bold">${agent.hostname}</div></div>
        `)}
        <br/>
      </div>
    `;
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/actifs.png" class="img_icons"/>Actifs impactés
        </section-header>
        <br/>
        ${this.renderActifs()}
      </div>
    `;
  }

}

customElements.define('incident-block-actifs', Block);