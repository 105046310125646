import { BaseElement, html, css, unsafeHTML } from 'Components';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:22px;
        }
        .text {
          margin-left: 10px;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  goToPreplainte() {
    window.open('https://www.pre-plainte-en-ligne.gouv.fr/', '_blank');
  }

  goToCnil() {
    window.open('https://notifications.cnil.fr/notifications/index', '_blank');
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/complementaire.png" class="img_icons"/>Informations complémentaires
        </section-header>
        <br/>
        ${this.parent.who === 'cert-aviation-france' ? html`
        <div class="margin text flex">
          <div>
            <img src="/assets/logos/dgac.jpg" width="86"/>
          </div>
          <div>
            En application du règlement <a-href-confirm href="https://www.ecologie.gouv.fr/sites/default/files/Guide_reglement-376-2014.pdf" target=_blank">n°376/2014 du parlement européen et du conseil du 3 avril 2014 concernant les comptes rendus,
            l'analyse et le suivi d'événements dans l'aviation civile</a-href-confirm>, un opérateur qui subit une attaque cyber avec un impact direct
            ou indirect sur le fonctionnement de l’aéroport ou de la compagnie aérienne (impact sur le traitement des passagers,
            des bagages, sur les vols, etc.) doit en informer la <a-href-confirm href="https://lannuaire.service-public.fr/gouvernement/8c94b894-935c-472c-9ba8-be4f4aa7dda0" target="_blank">DSAC/IR</a-href-confirm>.
            <br/><br/>
          </div>
        </div>
        ` : ''}
        <div class="margin text flex">
          <div>
            <img src="/assets/logos/minint.png" width="86"/>
          </div>
          <div>
            Conformément à l'article 5 de <a-href-confirm href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000047046768" target="_blank">la loi du 24 janvier 2023 d'orientation et de programmation du ministère de
            l'Intérieur (LOPMI)</a-href-confirm> qui oblige, depuis le 24 avril 2023, tous les professionnels à effectuer un dépôt de plainte dans un délai de 72 heures
            suivants la connaissance d’une cyberattaque pour pouvoir être indemnisé par leur assureur, sous réserve que leur contrat le prévoit.
            <br/><br/>
            <sl-button @click="${this.goToPreplainte}" pill variant="primary" class="noprint">
              <m-icon slot="suffix" name="navigate_next" nogradient></m-icon>
              Pré-remplir mon dépot de plainte
            </sl-button>
          </div>
        </div>
        <br/>
        <br/>
        <div class="margin text flex">
          <div>
            <img src="/assets/logos/cnil.jpg" width="86"/>
          </div>
          <div>
            Si des données personnelles d’utilisateurs ont été volées, une déclaration auprès de la CNIL est obligatoire dans les 72 heures qui suivent la cyberattaque. 
            <br/><br/>
            <sl-button @click="${this.goToCnil}" pill variant="primary" class="noprint">
              <m-icon slot="suffix" name="navigate_next" nogradient></m-icon>
              Notifier une violation de données personnelles
            </sl-button>
          </div>
        </div>
        <br/>
      </div>
    `;
  }

}

customElements.define('incident-block-infos-more', Block);