import { BaseElement, html, css, unsafeHTML } from 'Components';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`
        .img_icons {
          height:18px;
        }

        .text {
          margin-left:10px;
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin">
        <section-header size="medium">
          <img slot="left" src="assets/img/description.png" class="img_icons"/>
          ${this.parent.isIncident() ? "Description de l'incident" : 'Description de l\'alerte'}
        </section-header>
        <br/>
        <div class="margin text">${unsafeHTML(this.parent.ticket.incidentResumeText.join('<br/>'))}</div>
      </div>
    `;
  }

}

customElements.define('incident-block-description', Block);