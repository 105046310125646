import { BaseElement, html, css } from 'Components';
import { Session } from 'Utils';

class Part extends BaseElement {
  static get styles() {
    return [
      css`
        a.noline {
          text-decoration:none;
        }

        .socbuttons {
          /*background-color: var(--sl-color-neutral-300);*/
          background-color: var(--nsys-blue5);
          padding:10px;
          position:fixed;
          /*top:47px;*/
          bottom:15px;
          z-index:20;
          width:900px;
          margin-left:-10px;
          border-radius:5px;
          box-shadow:0px 0px 5px 5px #00000044;
        }

        ul {
          margin:20px;
        }

        @media print {
          sl-button {
            display:none
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  modalShow(ev) {
    let modal;
    const modalId = ev.target.getAttribute('data-modal-id');
    if (modalId) {
      modal = document.getElementById(modalId) || this.shadowRoot.getElementById(modalId);
      if (modal) {
        modal.show();
        return;
      }
    } else {
      modal = ev.target.nextElementSibling;
      if (modal.tagName === 'MODAL-DIALOG') {
        modal.show();
        return;
      }
    }

    console.error('Could not find modal-dialog');
  }

  modalClose(ev) {
    const modalDialog = ev.target.closest('modal-dialog');
    if (modalDialog) {
      modalDialog.hide();
    }
  }

  render() {

    if (!this.parent.isEditing()) return;

    const pill = '';

    return html`
      <div class="socbuttons">
        ${
          this.parent.isTicketTaken()
          ? this.parent.isTicketTakenByMe()
            ? html`
              <a class="noline" href="${this.parent.ticket.socInternal.harfangUrl}" target="_blank">
                <sl-button variant="primary" ${pill} title="Ouvrir une nouvelle fenêtre sur l'alerte HarfangLab">HarfangLab</sl-button>
              </a>
              ${this.parent.isResolved()
                ? ''
                : html`
                    <sl-button variant="primary" ?hide=${this.parent.isResolved()} ${pill} @click="${this.modalShow}" data-modal-id="modal-incident-fp" title="Clôturer l'alerte en faux positif">Faux positif</sl-button>
                    <modal-dialog label="Confirmation" id="modal-incident-fp" modal>
                      <p>
                        Après analyse, vous considérez que cette alerte est un faux positif.
                        <ul>
                          ${this.parent.isIncident() 
                            ? html`<li>L'incident va être clôturée.</li><li>Une notification va être envoyée au client.</li>`
                            : html`<li>Aucune notification vers le client à ce stade.</li>`
                          }
                          ${this.parent.ticket.socInternal?.harfangUrl 
                            ? html`<li><a href="${this.parent.ticket.socInternal.harfangUrl}" target="_blank">L'alerte HarfangLab</a> correspondante va passer en status "false_positive"</li>` 
                            : ''
                          }
                        </ul>
                      </p>
                      <sl-button slot="bt1" variant="text" @click="${this.modalClose}">Annuler</sl-button>
                      <sl-button slot="bt2" variant="primary" @click="${this.parent.toFalsePositive}">Clôturer l'alerte</sl-button>
                    </modal-dialog>`
                }
              ${this.parent.isResolved()
                ? ''
                : this.parent.isIncident()
                  ? html`
                    <sl-button variant="danger" ${pill} @click="${this.modalShow}" data-modal-id="modal-incident-close">Cloturer l'incident</sl-button>
                    <modal-dialog label="Confirmation" id="modal-incident-close" modal>
                      <p>
                        Vous êtes sur le point cloturer l'incident.
                        <ul>
                          <li>Une notification sera envoyée au client.</li>
                        </ul>
                      </p>
                      <sl-button slot="bt1" variant="text" @click="${this.modalClose}">Annuler</sl-button>
                      <sl-button slot="bt2" variant="primary" @click="${this.parent.toClose}">Clôturer l'incident</sl-button>
                    </modal-dialog>

                    <sl-button variant="warning" ${pill} @click="${this.modalShow}" data-modal-id="modal-incident-notify" style="float:right;">Notifier le client</sl-button>
                    <modal-dialog label="Confirmation" id="modal-incident-notify" modal>
                      <p>
                        Vous êtes sur le point d'informer le client sur les changements que vous avez effectué sur cet incident.
                        <ul>
                          <li>Une notification sera envoyée au client.</li>
                        </ul>
                      </p>
                      <sl-button slot="bt1" variant="text" @click="${this.modalClose}">Annuler</sl-button>
                      <sl-button slot="bt2" variant="primary" @click="${this.parent.toClose}">Envoyer une notification</sl-button>
                      </div>
                    </modal-dialog>`
                  : html`
                    <sl-button variant="danger" ?hide=${this.parent.isResolved()} ${pill} data-modal-id="modal-incident-escalade" @click="${this.modalShow}">Escalader en incident</sl-button>
                    <modal-dialog label="Confirmation" id="modal-incident-escalade" modal>
                      <p>
                        Vous êtes sur le point d'escalader cette alerte en incident.
                        <ul>
                          <li>Une notification sera envoyée au client.</li>
                        </ul>
                      </p>
                      <sl-button slot="bt1" variant="text" @click="${this.modalClose}">Annuler</sl-button>
                      <sl-button slot="bt2" variant="primary" @click="${this.parent.toIncident}">Transformer en incident</sl-button>
                    </modal-dialog>`
              }
              `
            : html`
                <sl-button variant="warning" ${pill} @click="${this.modalShow}" data-modal-id="modal-incident-take-over" >Prendre en charge</sl-button>
                <modal-dialog label="Confirmation" id="modal-incident-take-over" modal>
                  <p>
                    Attention: cette alerte est actuellement en cours d'investigation par ${this.parent.ticket.owner.realName}.<br/><br/>
                    Vous êtes sur le point de prendre en charge cette alerte à sa place.<br/>
                    <ul>
                      <li>Aucune notification ne sera envoyé au client concernant ce changement d'analyste car l'alerte n'est pas (encore) un incident.</li>
                    </ul>
                  </p>
                  <sl-button slot="bt1" variant="text" id="bttakecancel" @click="${this.modalClose}">Annuler</sl-button>
                  <sl-button slot="bt2" variant="primary" id="bttake" @click="${this.parent.takeTicket}">Je prends en charge</sl-button>
                  </div>
                </modal-dialog>`
        
          : html`
              <sl-button variant="warning" ${pill} @click="${this.modalShow}" data-modal-id="modal-incident-take">Prendre en charge</sl-button>
              <modal-dialog label="Confirmation" id="modal-incident-take" modal>
                <p>
                  Vous êtes sur le point de prendre en charge cette alerte. 
                  <ul>
                    <li>Aucune notification vers le client à ce stade, seul une escalade en incident déclenchera les notifications.</li>
                    <li>L'alerte HarfangLab</a> correspondante va passer en status "investigating"</li>
                  </ul>
                </p>
                <sl-button slot="bt1" variant="text" id="bttakecancel" @click="${this.modalClose}">Annuler</sl-button>
                <sl-button slot="bt2" variant="primary" id="bttake" @click="${this.parent.takeTicket}">Je prends en charge</sl-button>
              </modal-dialog>`
        }
      </div>
    `;
  }

}

customElements.define('incident-buttons-soc', Part);