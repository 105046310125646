import { BaseElement, html, css, unsafeHTML } from 'Components';
import { Session } from 'Utils';

import styles from './styles.js';

class Block extends BaseElement {
  static get styles() {
    return [
      styles,
      css`

        h3 .img_icons {
          height:22px;
        }

        m-icon[name="print"] {
          cursor:pointer;
        }

        .print_alert {
          padding:20px;
          display:flex;
          font-size:16px;
          background: var(--page-background-gradient);
          border-radius: 5px;
          margin-top: 7px;
          border: 1px solid var(--nsys-blue3s);
          backdrop-filter: blur(3px);
        }

        .print_alert m-icon {
          font-size:40px;
          margin-right:20px;
        }

        @media print {
          .noprint {
            display:none;
          }
        }
      `
    ];
  }

  static get properties() {
    return {
      parent: { type: Object }
    };
  }

  print() {
    window.scrollTo(0, 0); // Défile jusqu'en haut de la page
    setTimeout(() => {
      window.print(); // Lance l'aperçu d'impression après un court délai
    }, 2000); // Ajustez le délai au besoin
  }

  render() {
    if (!this.parent.ticket) return;

    return html`
      <div class="margin noprint print_alert">
        <m-icon name="print" @click="${this.print}" nogradient></m-icon>
        <span>
          Il est important d'être en haut de la page avant d'imprimer le document, sinon la mise en page peut être incorrecte lors de l'aperçu et de l'impression.
          Cliquez l'icône pour imprimer le document.
        </span>
      </div>
    `;
  }

}

customElements.define('incident-block-print', Block);