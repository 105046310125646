import { FullPageElement, html, css } from 'Components';
import { Router } from 'Router';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

class CTIHome extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      css`

      .background_home {
        background-color:#164366;
        min-height:45vh;
        display:flex;
        position:relative;
      }

      .background_home_img {
        position:absolute;
        display:flex;
        min-height:45vh;
        width:100%;
        background-size: cover;
        background-image: url('assets/img/visuals/magnifier.jpg');
        opacity: 1:
      }

      .big_title {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to right, transparent 0%, #111111bb 20%, #111111bb 80%, transparent 100%);
        padding:10px;
        width:100%;
        color:white;
        text-transform:uppercase;
        text-shadow: 1px 1px 1px #000000;
        font-size:2em;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:top;
        flex-direction:column;
      }

      .center {
        text-align:center;
        display:block;
        padding:20px;
      }

      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            main:{
              title:'Cybersecurity incidents',
              text:'Declare or follow a security incident, make a request for assistance or follow a doubt removal.',
              button:'Access my folder'
            }
          },
        },
        french:{
          translation: {
            main:{
              title:'Traitement des incidents Cybersécurité',
              text:'Déclarer ou suivre un incident de sécurité, effectuer une demande d\'assistance ou suivre une levée de doute.',
              button:'Accéder à mon dossier'
            }
          }
        }
      }
    ]
  }
  
  render() {
    return html`
      <div class="background_home">
        <div class="background_home_img"></div>
          <div class="big_title"><div>${unsafeHTML(this._tl('main.title'))}</div></div>
        </div>
      </div>
      <br/>
      <div class="center">
        ${this._tl('main.text')}
        <br/><br/>
        <sl-button @click="${this.goToFolder}" pill variant="primary">
          <m-icon slot="suffix" size="big" name="navigate_next" nogradient></m-icon>
          ${this._tl('main.button')}
        </sl-button>
      </div>
    `;
  }

  goToFolder() {
    Router.go('/public/mondossier/etape1');
    window.scrollTo(0, 0);
  }

}

customElements.define('page-cti', CTIHome);